import React from 'react';
import { ModalTransparentBackground, Close } from './elements';
import Theme from 'src/components/Theme';
import colors from 'src/style/colors';
import { SectionSubtitle } from 'src/components/Typography';
import { useMediaQueries } from '../../utils/MediaQueries';
import { mediaQueries } from 'src/style/breakpoints';

import FirstBlock from './blocks/FirstBlock';
import SuccessBlock from './blocks/SuccessBlock';
import ScrollLock from 'react-scrolllock/dist/ScrollLock';

const ModalForm = ({ onClose, isOpen, formSuccess, setFormSuccess, values, setValues }) => {
  const mqs = useMediaQueries();
  return (
    <>
      {!mqs.phoneOnly ? (
        <ModalTransparentBackground
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              onClose();
            }
          }}
          isOpen={isOpen}
        >
          <ScrollLock isActive={!mqs.phoneOnly} />
          <Theme theme="secondary">
            <div
              css={(theme) => ({
                backgroundColor: theme.backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '95%',
                width: '465px',
                alignItems: 'stretch',
                padding: '64px',
                borderRadius: 5,
                boxSizing: 'content-box',
              })}
            >
              <button
                onClick={onClose}
                css={{
                  backgroundColor: colors.purpleDark,
                  position: 'absolute',
                  top: 30,
                  right: 30,
                  width: 60,
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  borderRadius: '50%',
                  WebkitMaskImage: '-webkit-radial-gradient(white, black)',
                  transitionProperty: 'all',
                  transitionDuration: '0.3s',
                  transitionTimingFunction: 'ease-in-out',
                  '&:before': {
                    content: '""',
                    backgroundColor: colors.purple,
                    position: 'absolute',
                    display: 'block',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    transform: 'scaleX(0)',
                    transformOrigin: '0 50%',
                    transitionProperty: 'transform',
                    transitionDuration: '0.3s',
                    transitionTimingFunction: 'ease-in-out',
                    zIndex: 1,
                  },
                  '&:hover:before': {
                    transform: 'scaleX(1)',
                  },
                }}
              >
                <Close colorFill={colors.white} />
              </button>
              {!formSuccess ? (
                <SectionSubtitle
                  css={{
                    lineHeight: '44px',
                    color: colors.purpleDark,
                    textAlign: 'left',
                    '&:after': {
                      content: '""',
                      display: 'inline-block',
                      height: 10,
                      width: 10,
                      marginLeft: 3,
                      backgroundColor: colors.yellow,
                      borderRadius: '50%',
                    },
                  }}
                >
                  Recevez notre programme <br />
                  de formation
                </SectionSubtitle>
              ) : (
                <SectionSubtitle
                  css={{
                    lineHeight: '44px',
                    color: colors.purpleDark,
                    textAlign: 'left',
                    '&:after': {
                      content: '""',
                      display: 'inline-block',
                      height: 10,
                      width: 10,
                      marginLeft: 3,
                      backgroundColor: colors.yellow,
                      borderRadius: '50%',
                    },
                  }}
                >
                  Programme envoyé{' '}
                </SectionSubtitle>
              )}

              {!formSuccess ? (
                <>
                  <FirstBlock setValues={setValues} setFormSuccess={setFormSuccess} />
                </>
              ) : (
                <SuccessBlock values={values} onClose={onClose} setFormSuccess={setFormSuccess} />
              )}
            </div>
          </Theme>
        </ModalTransparentBackground>
      ) : (
        <Theme theme="secondary">
          <div
            css={(theme) => ({
              backgroundColor: theme.backgroundColor,
              position: 'fixed',
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '100%',
              width: '100%',
              zIndex: 9000,
              top: 0,
              left: 0,
              display: isOpen && mqs.phoneOnly ? 'flex' : 'none',
              flexDirection: 'column',
              padding: 30,
            })}
          >
            <button
              css={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}
              onClick={onClose}
            >
              <Close colorFill={colors.purpleDark} />
            </button>
            {!formSuccess ? (
              <SectionSubtitle
                css={{
                  [mediaQueries.phoneOnly]: {
                    color: colors.purpleDark,
                    fontSize: '28px',
                    lineHeight: '35px',
                    textAlign: 'left',
                    '&:after': {
                      content: '""',
                      display: 'inline-block',
                      height: 7,
                      width: 7,
                      marginLeft: 3,
                      backgroundColor: colors.yellow,
                      borderRadius: '50%',
                    },
                  },
                }}
              >
                Recevez notre programme <br />
                de formation
              </SectionSubtitle>
            ) : (
              <SectionSubtitle
                css={{
                  [mediaQueries.phoneOnly]: {
                    color: colors.purpleDark,
                    fontSize: '28px',
                    lineHeight: '35px',
                    textAlign: 'left',
                    '&:after': {
                      content: '""',
                      display: 'inline-block',
                      height: 7,
                      width: 7,
                      marginLeft: 3,
                      backgroundColor: colors.yellow,
                      borderRadius: '50%',
                    },
                  },
                }}
              >
                Programme envoyé{' '}
              </SectionSubtitle>
            )}

            {!formSuccess ? (
              <>
                <FirstBlock setValues={setValues} setFormSuccess={setFormSuccess} />
              </>
            ) : (
              <SuccessBlock values={values} onClose={onClose} setFormSuccess={setFormSuccess} />
            )}
          </div>
        </Theme>
      )}
    </>
  );
};

export default ModalForm;
