import React from 'react';
import { Paragraph, StyledLinkNoURL, SentenceSmall } from 'src/components/Typography';
import { FormHeaderSubTitle } from 'src/components/Form/elements';
import { NextBtn } from '../../Form/elements';

const SuccessBlock = ({ values, setFormSuccess, onClose }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '460px',
      }}
    >
      <br /> <br /> <br />
      <FormHeaderSubTitle>Merci {values.firstName} !</FormHeaderSubTitle>
      <Paragraph css={{ textAlign: 'center' }}>
        Le programme vous a été envoyé sur : <br />
        <StyledLinkNoURL>{values.email}</StyledLinkNoURL>
        <br />
        <br />
      </Paragraph>
      <SentenceSmall css={{ textAlign: 'center' }}>
        N'oubliez pas de vérifier vos SPAMs (ou vos communications commerciales si vous utilisez
        Gmail).
      </SentenceSmall>
      <NextBtn
        onClick={() => {
          setFormSuccess(false);
          onClose();
        }}
      >
        Fermer
      </NextBtn>
    </div>
  );
};

export default SuccessBlock;
